<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  props: {},
  components: {},
  data () {
    return {
    }
  },
  // 计算属性
  computed: {
  },
  // 创建后
  created () {
  },
  //挂载后
  mounted () {
  },
  // 方法
  methods: {
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul,
li {
  list-style: none;
  outline: none;
}
a {
  text-decoration: none;
}
img {
  vertical-align: middle;
  border: 0;
}
html,
body {
  height: 100%;
  background: #fafafa;
}
#app {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
  text-rendering: optimizeLegibility;
  // text-align: center;
  color: #202020;
  height: 100%;
}
</style>
