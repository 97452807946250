import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { Message } from "element-ui";

import Layout from '@/views/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    name: 'Test',
    component: ()=> import('@/views/test'),
  },
  // 登录
  {
    path: '/',
    name: '',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'login',
    component: ()=> import('@/views/login/index'),
  },
  // 账户信息
  {
    path: '/',
    component: Layout,
    redirect: '/accountInfo',
    alwaysShow: true,
    children: [
      {
        path: 'accountInfo',
        name: 'AccountInfo',
        component: () => import('@/views/accountInfo/index'),
        meta: { title: '账户信息', icon: 'el-icon-user', affix: true }
      }
    ]
  },
  // 通知消息
  {
    path: '/message',
    component: Layout,
    redirect: '/message/index',
    alwaysShow: true,
    children: [
      {
        path: '/message/index',
        name: 'MessageIndex',
        component: () => import('@/views/message/index'),
        meta: { title: '消息中心', icon: 'el-icon-message-solid', affix: true }
      }
    ]
  },
  // 首页
  {
    path: '/',
    redirect: '/home',
    component: Layout,
    children:[
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/index'),
        meta: { title: '首页', icon: 'el-icon-home'}
      }
    ]
  },
  // 系统管理
  {
    path:'/system',
    name:'system',
    redirect: '/system/role',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '系统管理',
      icon: 'el-icon-xitongguanli8',
    },
    children: [
      {
        path: '/system/menu',
        name: 'menu',
        component: () => import('@/views/system/menu'),
        meta: { title: '菜单管理', icon: 'el-icon-caidan'}
      },
      {
        path: '/system/role',
        name: 'role',
        component: () => import('@/views/system/role'),
        meta: { title: '角色管理', icon: 'el-icon-role'}
      },
      {
        path: '/system/log',
        name: 'log',
        component: () => import('@/views/system/log'),
        meta: { title: '访问日志', icon: 'el-icon-DCSrizhi'}
      }
    ]
  },
  // 基础数据
  {
    path:'/basic',
    name:'basic',
    redirect: '/basic/role',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '基础数据',
      icon: 'el-icon-document_settings',
    },
    children: [
      {
        path: '/basic/entity',
        name: 'entity',
        component: () => import('@/views/basic/entity'),
        meta: { title: '企业主体', icon: 'el-icon-qiyezhuti'}
      },
      {
        path: '/basic/flow_type',
        name: 'flowType',
        component: () => import('@/views/basic/flowType'),
        meta: { title: '审批类型', icon: 'el-icon-shenpileixingguanli'}
      },
      {
        path: '/basic/flow',
        name: 'flow',
        component: () => import('@/views/basic/flow'),
        meta: { title: '审批流程', icon: 'el-icon-flow'}
      },
      {
        path: '/basic/expense_cate',
        name: 'expenseCate',
        component: () => import('@/views/basic/expenseCate'),
        meta: { title: '报销类型', icon: 'el-icon-daijiesuan'}
      },
      {
        path: '/basic/cost_cate',
        name: 'costCate',
        component: () => import('@/views/basic/costCate'),
        meta: { title: '费用类型', icon: 'el-icon-feiyongleixing'}
      },
      {
        path: '/basic/payment',
        name: 'payment',
        component: () => import('@/views/basic/payment'),
        meta: { title: '付款类型', icon: 'el-icon-fukuanleixingdingyi'}
      },
      {
        path: '/basic/expert',
        name: 'expert',
        component: () => import('@/views/basic/expert'),
        meta: { title: '专家管理', icon: 'el-icon-kehu2'}
      },
      {
        path: '/basic/supplier',
        name: 'supplier',
        component: () => import('@/views/basic/supplier'),
        meta: { title: '供货商管理', icon: 'el-icon-gonghuoshang'}
      },
      {
        path: '/basic/people',
        name: 'people',
        component: () => import('@/views/basic/people'),
        meta: { title: '打款个人管理', icon: 'el-icon-userinfo1'}
      },
    ]
  },
  // 人力资源
  {
    path:'/manpower',
    name:'manpower',
    redirect: '/manpower/department',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '人力资源',
      icon: 'el-icon-renliziyuan',
    },
    children: [
      {
        path: '/manpower/department',
        name: 'department',
        component: () => import('@/views/manpower/department'),
        meta: { title: '部门架构', icon: 'el-icon-hierarchy'}
      },
      {
        path: '/manpower/user',
        name: 'user',
        component: () => import('@/views/manpower/user'),
        meta: { title: '企业员工', icon: 'el-icon-qiyeyuangong'}
      },
    ]
  },
  // 办公审批（申请）
  {
    path:'/approve',
    name:'approve',
    redirect: '/approve/reimburse',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '办公审批',
      icon: 'el-icon-bangong-shenpi',
    },
    children:[
      // 报销申请
      {
        path: '/approve/reimburse',
        name: 'reimburse',
        component: () => import('@/views/approve/index'), // Parent router-view
        redirect: '/approve/reimburse/initiated',
        meta: { title: '报销申请', icon: 'el-icon-baoxiao1'},
        children:[
          // 我发起的
          {
            path: '/approve/reimburse/initiated',
            name: 'refundInitiated',
            component: () => import('@/views/approve/reimburse/initiated.vue'),
            meta: { title: '我发起的', icon: 'el-icon-position'}
          },
          // 我处理的
          {
            path: '/approve/reimburse/processed',
            name: 'refundProcessed',
            component: () => import('@/views/approve/reimburse/processed.vue'),
            meta: { title: '我处理的', icon: 'el-icon-coordinate'}
          },
        ]
      },
      // 发票申请
      {
        path: '/approve/invoice',
        name: 'invoice',
        component: () => import('@/views/approve/index'), // Parent router-view
        redirect: '/approve/invoice/initiated',
        meta: { title: '发票申请', icon: 'el-icon-fapiaoguanli'},
        children:[
          // 我发起的
          {
            path: '/approve/invoice/initiated',
            name: 'refundInitiated',
            component: () => import('@/views/approve/invoice/initiated.vue'),
            meta: { title: '我发起的', icon: 'el-icon-position'}
          },
          // 我处理的
          {
            path: '/approve/invoice/processed',
            name: 'refundProcessed',
            component: () => import('@/views/approve/invoice/processed.vue'),
            meta: { title: '我处理的', icon: 'el-icon-coordinate'}
          },
        ]
      },
      // 借款申请
      {
        path: '/approve/borrow',
        name: 'borrow',
        component: () => import('@/views/approve/index'), // Parent router-view
        redirect: '/approve/borrow/initiated',
        meta: { title: '借款申请', icon: 'el-icon-jiekuandan'},
        children:[
          // 我发起的
          {
            path: '/approve/borrow/initiated',
            name: 'borrowInitiated',
            component: () => import('@/views/approve/borrow/initiated.vue'),
            meta: { title: '我发起的', icon: 'el-icon-position'}
          },
          // 我处理的
          {
            path: '/approve/borrow/processed',
            name: 'borrowProcessed',
            component: () => import('@/views/approve/borrow/processed.vue'),
            meta: { title: '我处理的', icon: 'el-icon-coordinate'}
          },
        ]
      },
      // 付款申请
      {
        path: '/approve/payment',
        name: 'payment',
        component: () => import('@/views/approve/index'), // Parent router-view
        redirect: '/approve/payment/initiated',
        meta: { title: '付款申请', icon: 'el-icon-fukuanguanli'},
        children:[
          // 我发起的
          {
            path: '/approve/payment/initiated',
            name: 'paymentInitiated',
            component: () => import('@/views/approve/payment/initiated.vue'),
            meta: { title: '我发起的', icon: 'el-icon-position'}
          },
          // 我处理的
          {
            path: '/approve/payment/processed',
            name: 'paymentProcessed',
            component: () => import('@/views/approve/payment/processed.vue'),
            meta: { title: '我处理的', icon: 'el-icon-coordinate'}
          },
        ]
      },
      // 还款申请
      {
        path: '/approve/refund',
        name: 'refund',
        component: () => import('@/views/approve/index'), // Parent router-view
        redirect: '/approve/refund/initiated',
        meta: { title: '还款申请', icon: 'el-icon-huankuanguanli'},
        children:[
          // 我发起的
          {
            path: '/approve/refund/initiated',
            name: 'refundInitiated',
            component: () => import('@/views/approve/refund/initiated.vue'),
            meta: { title: '我发起的', icon: 'el-icon-position'}
          },
          // 我处理的
          {
            path: '/approve/refund/processed',
            name: 'refundProcessed',
            component: () => import('@/views/approve/refund/processed.vue'),
            meta: { title: '我处理的', icon: 'el-icon-coordinate'}
          },
        ]
      },
      // 合同申请
      {
        path: '/approve/contract',
        name: 'contract',
        component: () => import('@/views/approve/index'), // Parent router-view
        redirect: '/approve/contract/initiated',
        meta: { title: '合同申请', icon: 'el-icon-hetong1'},
        children:[
          // 我发起的
          {
            path: '/approve/contract/initiated',
            name: 'refundInitiated',
            component: () => import('@/views/approve/contract/initiated.vue'),
            meta: { title: '我发起的', icon: 'el-icon-position'}
          },
          // 我处理的
          {
            path: '/approve/contract/processed',
            name: 'refundProcessed',
            component: () => import('@/views/approve/contract/processed.vue'),
            meta: { title: '我处理的', icon: 'el-icon-coordinate'}
          },
        ]
      },
    ]
  },
  // 财务管理
  {
    path:'/finance',
    name:'finance',
    redirect: '/finance/invoice',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '财务管理',
      icon: 'el-icon-caiwu',
    },
    children: [
      {
        path: '/finance/invoice',
        name: 'invoice',
        component: () => import('@/views/finance/invoice'),
        meta: { title: '开票管理', icon: 'el-icon-fapiao1'}
      },
      {
        path: '/finance/income',
        name: 'income',
        component: () => import('@/views/finance/income'),
        meta: { title: '到账管理', icon: 'el-icon-daozhangguanli'}
      },
      {
        path: '/finance/remit',
        name: 'remit',
        component: () => import('@/views/finance/remit'),
        meta: { title: '打款管理', icon: 'el-icon-dakuanjilu'}
      },
    ]
  },
  // 客户管理
  {
    path:'/customer',
    name:'customer',
    redirect: '/customer/index',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '客户管理',
      icon: 'el-icon-kehu',
    },
    children: [
      {
        path: '/customer/grade',
        name: 'customerGrade',
        component: () => import('@/views/customer/grade'),
        meta: { title: '客户等级', icon: 'el-icon-zhiji'}
      },
      {
        path: '/customer/index',
        name: 'customerList',
        component: () => import('@/views/customer/index'),
        meta: { title: '客户列表', icon: 'el-icon-kehu3'}
      },
      // {
      //   path: '/customer/contact',
      //   name: 'customerContact',
      //   component: () => import('@/views/customer/contact'),
      //   meta: { title: '客户联系人', icon: 'el-icon-kehulianxiren'}
      // },
    ]
  },
  // 合同管理
  {
    path: '/contract',
    redirect: '/contract/index',
    component: Layout,
    children:[
      {
        path: '/contract/index',
        name: 'contractIndex',
        component: () => import('@/views/contract/index'),
        meta: { title: '合同管理', icon: 'el-icon-hetongguanli'}
      }
    ]
  },
  // 项目管理
  {
    path:'/project',
    name:'project',
    redirect: '/project/index',
    component: Layout,
    alwaysShow: true,
    meta: {
      title: '项目管理',
      icon: 'el-icon-xiangmuguanli',
    },
    children: [
      {
        path: '/project/index',
        name: 'projectList',
        component: () => import('@/views/project/index'),
        meta: { title: '项目列表', icon: 'el-icon-xiangmuliebiao1'}
      },
      {
        path: '/project/event',
        name: 'projectEvent',
        component: () => import('@/views/project/event'),
        meta: { title: '项目事件', icon: 'el-icon-xiangmuliebiao'}
      },
      
    ]
  },
  // 系统公告
  {
    path: '/notice',
    redirect: '/notice/index',
    component: Layout,
    children:[
      {
        path: '/notice/index',
        name: 'NoticeIndex',
        component: () => import('@/views/notice/index'),
        meta: { title: '系统公告', icon: 'el-icon-bell'}
      }
    ]
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach((to, from, next) => {
  // console.error(to, from, '=== created == beforeEach ===');
  const matcheds = to.matched||[];
  const {name} = matcheds[0]||{};
  const otherRouterNames = [
    'AccountInfo',
    'NoticeIndex',
    'MessageIndex',
    'Test'
  ];
  if(otherRouterNames.includes(to.name)){
    to.meta.activeMenu = from.meta.activeMenu||from.path||to.meta.activeMenu;
  }
  if (to.name != 'login' && !localStorage.getItem("token")) {
    next({
      name: 'login'
    })
  } else {
    const { menu } = store.state;
    const { width=0, mini=true } = Vue.prototype.$config;
    if(!mini){
      sessionStorage.setItem('collapse', true);
      store.dispatch('setSideBar', true);
    }
    if (!otherRouterNames.includes(to.name) && to.name != 'login' && menu && !hasValue(menu, to.path)) {
      next('/home');
      // Message.error({
      //   message:"暂无权限访问！",
      // });
      return false;
    }
    next();
  }
})

const hasValue = (arr=[], target='') =>{
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    if (item.uri === target) {
      return true;
    } else if (Array.isArray(item.childs)) {
      if (hasValue(item.childs, target)) {
        return true;
      }
    }
  }
  return false;
}

export default router
