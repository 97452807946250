import request from "../axios";

// 登录
export function loginFn(data) {
  return request({
    url: "/auth/login",
    method: "post",
    data,
  });
}

// 退出登录登录
export function logoutFn(params) {
  return request({
    url: "/auth/logout",
    method: "get",
    params,
  });
}

// 获取权限数据
export function getAuth(params) {
  return request({
    url: "/auth/route",
    method: "get",
    params,
  });
}

// 修改个人信息
export function updateUserInfo(data) {
  return request({
    url: "/auth/edit",
    method: "post",
    data,
  });
}