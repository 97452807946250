import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Dayjs from 'dayjs'
// import OSS from 'ali-oss'
import { getQiniuToken, aliyunSample } from '@/server/api/common'
import { logoutFn } from '@/server/api/login'
// Element Ui
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"

import "@/assets/css/common.scss"
import '@/assets/iconfont/iconfont.css'


Vue.config.productionTip = false
// Element Ui 组件
Vue.use(ElementUI, { size: "small" })

// 视图窗口的配置
function setConfig(){
  const { width=0 } = Vue.prototype.$config || {};
  const windowWidth = window.innerWidth;
  const mini = windowWidth > 768;
  if ( width != windowWidth) {
    Vue.prototype.$config = {
      width: windowWidth,
      mini,
      pager_count: mini ? 7 : 5,
      pager_layout: mini ? 'prev, pager, next, jumper' : 'prev, pager, next'
    };
  }
  sessionStorage.setItem('collapse', !mini);
  store.dispatch('setSideBar', !mini);
}
setConfig();
window.addEventListener('resize', setConfig);

// 正则 
Vue.prototype.$regex = {
  file: /\.(pdf|docx|doc|xlsx|xls|pptx|ppt|txt)$/i,
  image: /\.(jpeg|jpg|png|gif)$/i,
  video: /\.(mp4|avi|wmv|mov|mpg|rm|rmvb|mkv)$/i,
  audio: /\.(mp3|wav|aac|ogg)$/i,
  // animation: /\.(swf)$/i,
  // book: /\.(epub|html)$/i,
  // compress: /\.(zip|rar)$/i,
  preview: /\.(pdf|docx|doc|xlsx|xls|pptx|ppt|txt)$/i,
  hasDomainInUrl: /^https?:\/\/.*?\..+$/,
  code: /^[a-zA-Z_]*$/, // 字典编码
  phone: /^(1[3456789]\d{9})$/,  // 手机号
  tel: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, // 座机号|手机号
  card: /^[1-9]\d{5}(18|19|20)?\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|X|x)?$/,  // 【/^\d{17}([0-9]|X|x)$/】18位  // 身份证号
  bank: /^\d{9,19}$/, // 银行卡号13-19位数字
  password: /^(?=.*[\da-zA-Z!@#$%^&*()\-_=+{};:,<.>|\[\]\\\/?]).{6,}$/, // 长度至少为6位，且至少包含一个字母、数字或特殊字符，不允许空格和中文
  // password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>|\[\]\\\/?])(?!.*[\u4e00-\u9fa5])\S{6,20}$/, // 长度为6-20位 大写|小写字母、数字、字符至少2种 不允许有空格,中文
}

// 获取七牛云上传凭证TOKEN
Vue.prototype.$getQiniuToken = async () => {
  Vue.prototype.$qiniuConfig = {
    offOn: false
  }
  const res = await getQiniuToken();
  // console.log(res, '=== res get qiniu token ===');
  if (res.code == 200 && Object.keys(res.data).length) {
    const { token = '', expiration = 0, domain='' } = res.data;
    Vue.prototype.$qiniuConfig = {
      offOn: true,
      token,
      domain,
      expiration:Dayjs(expiration).valueOf()
    };
  }
};

// // 阿里临时授权
// Vue.prototype.$getAliyunSample = async () => {
//   Vue.prototype.$aliyunConfig = {
//     offOn: false
//   }
//   const res = await aliyunSample();
//   // console.log(res, '=== res aliyunSample ===');
//   if (res.code == 200 && Object.keys(res.data).length) {
//     const { accesskey_id = '', accesskey_secret = '', security_token = '', expiration = 0 } = res.data;
//     // 阿里OSS
//     const client = new OSS({
//       // endpoint: 'https://oss-cn-beijing.aliyuncs.com',
//       // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
//       region: 'oss-cn-beijing',
//       // 填写Bucket名称，例如examplebucket。
//       bucket: 'zonghe-file',
//       // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
//       accessKeyId: accesskey_id,
//       accessKeySecret: accesskey_secret,
//       // 从STS服务获取的安全令牌（SecurityToken）。
//       stsToken: security_token,
//       // 如果使用 STS 临时凭证，你可能需要这样配置：
//       // 向您搭建的STS服务获取临时访问凭证。
//       refreshSTSToken: async () => {
//         const info = await aliyunSample();
//         // console.error(info);
//         if (info.code == 200 && Object.keys(info.data).length) {
//           const { accesskey_id = '', accesskey_secret = '', security_token = '',expiration = 0  } = info.data;
//           Vue.prototype.$aliyunConfig = {
//             ...Vue.prototype.$aliyunConfig,
//             expiration:Dayjs(expiration).valueOf(),
//           }
//           return {
//             accessKeyId: accesskey_id,
//             accessKeySecret: accesskey_secret,
//             stsToken: security_token
//           }
//         }
//       },
//       // 刷新临时访问凭证的时间间隔，单位为毫秒。
//       refreshSTSTokenInterval: 300000,
//       retryMax: 3,
//     });
//     Vue.prototype.$aliyunConfig = {
//       offOn: true,
//       client,
//       expiration:Dayjs(expiration).valueOf(),
//       // accessKeyId: accesskey_id,
//       // accessKeySecret: accesskey_secret,
//       // stsToken: security_token
//     }
//   }
// }

// 权限判断
Vue.prototype.$isPerms = (code) => {
  const { permission:perms } = store.state;
  const { username='' } = store.getters;
  return username === 'admin' || (perms && perms.includes(code));
}

// 退出登录
Vue.prototype.$logout = async () => {
  const res = await logoutFn();
  if (res.code == 200) {
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem('user');
    localStorage.removeItem('menu');
    localStorage.removeItem('permission');
    store.dispatch("setUser", null);
    store.dispatch("setMenu", null);
    store.dispatch("setPerms", null);
    router.replace({ name: 'login' });
    console.log('退出登录');
  }
}

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
