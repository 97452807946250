import request from "../axios";

/**
 * 系统菜单管理
 * **/
// 获取菜单列表数据
export function getMenu(params) {
  return request({
    url: "/system/menu",
    method: "get",
    params,
  });
}

// 菜单新增保存
export function createMenu(data) {
  return request({
    url: "/system/menu/save",
    method: "post",
    data,
  });
}

// 菜单编辑保存
export function updateMenu(data) {
  return request({
    url: "/system/menu/save",
    method: "put",
    data,
  });
}

// 菜单删除
export function deleteMenu(params) {
  return request({
    url: "/system/menu/delete",
    method: "delete",
    params,
  });
}

// 新增调用级联菜单
export function getMenuCascade(params) {
  return request({
    url: "/system/menu/cascade",
    method: "get",
    params,
  });
}

/**
 * 系统角色管理
 * **/
// 获取角色列表数据
export function getRoles(params) {
  return request({
    url: "/system/roles",
    method: "get",
    params,
  });
}

// 角色新增保存
export function createRoles(data) {
  return request({
    url: "/system/roles/save",
    method: "post",
    data,
  });
}

// 角色编辑保存
export function updateRoles(data) {
  return request({
    url: "/system/roles/save",
    method: "put",
    data,
  });
}

// 角色删除
export function deleteRoles(params) {
  return request({
    url: "/system/roles/delete",
    method: "delete",
    params,
  });
}

// 角色权限分配保存
export function savePerms(data) {
  return request({
    url: "/system/roles/bindmenu",
    method: "post",
    data,
  });
}

/**
 * 系统访问日志
 * **/
// 获取日志列表数据
export function getLog(params) {
  return request({
    url: "/system/operation-log",
    method: "get",
    params,
  });
}

/**
 * 通知管理
 * **/
// 获取通知列表数据
export function getMessage(params) {
  return request({
    url: "/system/message/index",
    method: "get",
    params,
  });
}

// 标记消息通知已读
export function signMessage(data) {
  return request({
    url: "/system/message/sign",
    method: "post",
    data,
  });
}

/**
 * 系统公告管理
 * **/
// 获取公告列表数据
export function getNotice(params) {
  return request({
    url: "/system/notice/list",
    method: "get",
    params,
  });
}

// 系统公告新增保存
export function createNotice(data) {
  return request({
    url: "/system/notice/store",
    method: "post",
    data,
  });
}

// 系统公告编辑保存
export function updateNotice(data) {
  return request({
    url: "/system/notice/store",
    method: "put",
    data,
  });
}

// 系统公告删除
export function deleteNotice(params) {
  return request({
    url: "/system/notice/delete",
    method: "delete",
    params,
  });
}
